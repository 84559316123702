.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.submitBtn {
    align-self: center;
    background-color: #000000;
    border-color: #000000;
}

.submitBtn:hover {
    background-color: rgb(44, 44, 44);
    border-color: rgb(4, 44, 44);
}

.errorTxt {
    font-size: 14px;
    color: rgb(204, 0, 0);
    font-family: 'Heldane';
}

.hexColorSelected {
    width: 20px;
    height: 20px;
    border: 1px solid #dee2e6;
    border-radius: 100%;
}

.insideOutsideFormTitle {
    font-family: 'Inter Medium';
    font-size: 14px;
}

.optionTitle {
    font-family: 'Inter Medium';
    font-size: .8rem;
}