@import "../../index.css";

@keyframes shake {
    0% {
        transform: rotate(0deg) translate(0, 0);
    }

    25% {
        transform: rotate(-2deg) translate(-2px, -2px);
    }

    50% {
        transform: rotate(2deg) translate(2px, 2px);
    }

    75% {
        transform: rotate(-2deg) translate(-2px, -2px);
    }

    100% {
        transform: rotate(0deg) translate(0, 0);
    }
}

/* ------ CardsCommands ------ */
.actionCommandsContainer {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    padding-left: 0;
    /* border-bottom: .2rem solid rgba(0, 0, 0, 0.1); */
    box-shadow: 0 8px 6px -5px rgba(0, 0, 0, 0.1);
    background-color: #000000;
}

.cardsCommandsRow {
    justify-content: flex-end;
}

.cardsCommandsCol {
    margin-right: 0.5em !important;
}

.actionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */
    height: auto;
    border: 1px solid #D9D9D9;
    background-color: transparent;
    color: #ffffff;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 10px 14px;
}

.actionBtnCNP {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */
    height: auto;
    border: 1px solid #FF6563;
    background-color: #FF6563;
    color: #ffffff;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 10px 14px;
}

.actionBtn svg:first-child,
.actionBtnCNP svg:first-child,
.toolCommandsActionBtn svg:first-child,
.cardsCommandsActionBtn svg:first-child {
    margin-right: .3rem;
}

.toolCommandsActionBtn,
.cardsCommandsActionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */
    height: auto;
    border: 1px solid #D9D9D9;
    background-color: transparent;
    color: #000000;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 10px 14px;
    min-height: 40px;
}

.actionBtn svg {
    font-size: 14px;
}

.actionBtn:hover,
.actionBtnCNP:hover,
.toolCommandsActionBtn:hover,
.cardsCommandsActionBtn:hover {
    background-color: #000000;
}

.actionBtn:disabled,
.actionBtnCNP:disabled,
.toolCommandsActionBtn:disabled,
.cardsCommandsActionBtn:disabled {
    background-color: transparent;
    color: #A1A1A1;
    border-color: #A1A1A1
}

.blackBtn {
    background-color: #000000 !important;
    color: #ffffff !important;
    animation: shake 1s infinite;
}

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1031;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

/* --------------------------- */

/* --------- ToolCommands ---------- */
.rangeBar {
    height: 6px;
}

/* en chrome y safari */
.rangeBar::-webkit-slider-runnable-track {
    height: .4rem;
}

/* en firebox */
.rangeBar::-moz-range-track {
    height: .4rem;
}

/* en Internet Explorer */
.rangeBar::-ms-track {
    height: .4rem;
}

.toolCommandsCol,
.cardsCommandsContainer {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 1rem;
    padding-right: 0;
}

.actionBtnRow {
    justify-content: center;
}

.actionBtnColumn {
    margin-right: 0.3em;
}

.prevNextContainer {
    display: flex;
    justify-content: space-around;
    gap: .5rem;
    margin-right: 0.5em;
}

.shareBtnCol {
    padding-left: 0px;
}

.shareBtnCol {
    padding-left: 0px;
}

.shareBtnsSharedPalette {
    margin-top: 0;
    display: flex;
    justify-content: flex-start;
}

.shareBtn {
    border-radius: 5px;
    background-color: #FF6563;
    border: none;
    font-size: 12px;
    font-family: 'Inter Medium';
    display: flex;
    align-items: center;
}

.shareBtnSharedPalette {
    margin-right: 1rem;
}

.shareBtn svg {
    margin-right: .3rem;
}

.optionsSubLabel {
    color: #A1A1A1;
    font-size: 10px;
}

.shareTextInputs::placeholder {
    font-size: 13px;
    color: #d5d2d2;
}

/* TOOLTIP */

.helpTooltip {
    font-size: 12px;
    text-align: left !important;
}

.helpTooltipText {
    margin-bottom: 0;
}

.helpTooltipBtn {
    background-color: #ffffff;
    color: #000000;
    border-color: #ffffff;
    font-size: 12px;
    width: 50%;
}

.helpTooltipBtn:focus,
.helpTooltipBtn:active {
    background-color: #FF6563 !important;
    border-color: #FF6563 !important;
}

.helpTooltipBtn:hover {
    background-color: #000000 !important;
    border-color: #000000 !important;
}

.colorCircle {
    width: 35px;
    height: 35px;
    border: 1px solid #000000;
    border-radius: 50px;
}



/* ----------------------------------------------------------------------- */



@keyframes pulse {
    from {
        transform: scale(0.8);
    }

    to {
        transform: scale(1.1);
    }
}


@media (width < 576px) {
    .shareBtnsSharedPalette {
        justify-content: center;
    }
}

@media (width < 442px) {
    .shareBtn {
        margin-top: 14px !important;
    }

    .toolCommandsCol {
        padding-left: 15rem;
    }

    .actionCommandsContainer {
        padding-right: 15rem;
    }
}

@media (width < 768px) {
    .actionBtnRow {
        justify-content: center;
    }

    .cardsCommandsRow {
        justify-content: center;
    }
}

/* --------------------------- */

/* TABLET */
@media (min-width: 768px) and (max-width: 992px) {
    .actionBtnRow {
        justify-content: center;
    }

    .cardsCommandsRow {
        justify-content: center;
    }
}

@media (width > 992px) {
    .toolCommandsCol {
        padding-inline-start: 7%;
    }

    .actionCommandsContainer,
    .cardsCommandsContainer {
        padding-inline-end: 7.5%;
    }
}

@media (width < 442px) {
    .shareBtn {
        margin-top: 14px !important;
    }
}

@media (width > 992px) {
    .toolCommandsCol {
        padding-inline-start: 8%;
        padding-inline-end: 7%;
    }
}

@media (width >=1200px) {
    .toolCommandsCol {
        padding-left: 7rem;
    }
}