@import "../../index.css";

.dropzone {
    border: solid 2px #4580ff81;
    border-radius: 15px;
    text-align: center;
    padding-top: 10px;
    cursor: pointer;
}

.addBtn {
    align-self: flex-end;
    background-color: #FF6563;
    border-color: #FF6563;
    border-radius: 3px;
    padding: .8rem;
    font-size: .8rem;
}

.addBtn:disabled {
    background-color: #ffffff;
    color: #A1A1A1;
    border-color: #A1A1A1
}

.addBtn:hover {
    background-color: #FF6563;
    border-color: #FF6563;
    opacity: 0.8;
}

.actionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */
    height: auto;
    border: 1px solid #D9D9D9;
    background-color: #ffffff;
    color: #000000;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 10px 14px;
}

.actionBtn:hover,
.actionBtn:active,
.actionBtn:focus {
    background-color: #000000;
    color: #ffffff;
}

.actionBtn svg:first-child {
    margin-right: .3rem;
}

.optionTitle {
    font-family: "Inter Regular";
    font-size: 1rem;
}

.optionData {
    font-family: 'Inter Medium';
    font-size: .7rem;
}

.dropdownItem svg {
    margin-right: 6px;
}

.dropdownItemNested {
    margin-left: 1.4rem;
}