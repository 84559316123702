@import "../../index.css";

.title {
    font-family: 'Livvic Bold';
    font-size: 50px;
    margin-bottom: 0.4em;
    text-align: center;
}

.card {
    background-color: transparent;
    padding: 1.5em;
    margin-bottom: 2em;
    font-family: 'Inter Medium';
}

.card label {
    font-size: 14px;
}

.editPaymentInfoBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 33px;
    border: 1px solid #D9D9D9;
    background-color: transparent;
    color: #000000;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 18px 14px;
}

.saveChangesBtn:hover {
    background-color: #ffffff;
    color: #FF6563;
    border-color: #FF6563;
}

.editSaveBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 33px;
    border: 1px solid #D9D9D9;
    background-color: transparent;
    color: #000000;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 18px 14px;
    float: right;
}

.editSaveBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 33px;
    border: 1px solid #D9D9D9;
    background-color: transparent;
    color: #000000;
    border-radius: 4px;
    font-family: 'Messina Sans Semi Bold';
    font-size: 12px;
    padding: 18px 14px;
    float: right;
}

.stripeSubscriptionContainer {
    display: flex;
    font-family: 'Inter Medium';
    align-items: baseline;
}

.stripeSubscriptionContainer p {
    margin-right: 6px;
    font-size: 14px;
}

.editPaymentInfoContainer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 0.7em;
}

.cancelSubscriptionText {
    color: #717272;
    display: flex;
    justify-content: end;
    font-size: 0.8rem;
}

.cancelSubscriptionText:hover {
    cursor: pointer;
    text-decoration: underline;
}

.actionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33px;
    border: 1px solid #D9D9D9;
    background-color: transparent;
    color: #000000;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 18px 14px;
}

/* .actionBtn svg:first-child {
    margin-right: .3rem;
} */

.actionBtn:hover,
.actionBtn:active,
.actionBtn:focus {
    background-color: #000000 !important;
    color: #ffffff;
}

.actionBtn svg {
    font-size: 14px;
}

.cardTitle {
    font-family: 'Inter Medium';
    margin-bottom: 1rem;
}

.disabledInput {
    color: #858484;
}

.cardTitle {
    font-family: 'Messina Sans Semi Bold';
    margin-bottom: 1rem;
}

.disabledInput {
    color: #858484;
}

/* ------------------------- My Palettes --------------------------- */

.myPalettesTitle {
    font-family: "Livvic Bold";
    font-size: 50px;
}

.paletteCard {
    border: 1px solid #d9d9d9;
    /* box-shadow: 5px 10px 20px 0px #e9e9e9; */
    box-shadow: 1px 3px 8px 4px #00000026;
    transition: 1s;
    height: 220px;
    align-items: center;
    align-content: center;
}

/* .paletteCard a {
    padding-block: 28%;
} */

.paletteCard:hover {
    background-color: #eeeeee;
    box-shadow: 5px 20px 20px 0px #ffffff;
}

.addBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    font-size: 12px;
    font-family: 'Inter Medium';
    color: #000000;
    text-align: center;
    /* height: 100%; */
}

.addBtn:hover,
.paletteAddOne:hover {
    cursor: pointer;
}

.paletteTitle,
.paletteDescription {
    font-family: 'Inter Medium';
}

.paletteTitle {
    font-size: 1rem;
    margin: 0;
}

.paletteDescription {
    font-size: 12px;
    color: #A6A6A6;
}

.paletteActions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.paletteCardLink:hover {
    cursor: pointer;
}

.paletteCardColumn {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}


/* ----------------------------------------------------------------- */

/* TABLET */
@media (min-width: 768px) and (max-width: 992px) {
    .card {
        width: 80%;
    }
}

@media (max-width: 576px) {
    .card {
        width: 100%;
    }
}

@media (width > 1400px) {
    .paletteCard {
        height: 255px;
    }
}

@media (width < 1200px) {
    .paletteCard {
        height: 175px;
    }
}

@media (width < 768px) {
    .paletteCard {
        height: 200px;
    }
}

@media (width < 500px) {
    .paletteCard {
        height: 180px;
    }
}

@media (width < 450px) {
    .paletteCard {
        height: 150px;
    }
}