@import '../../index.css';

.toolTitle {
    font-size: 2rem;
    text-align: center;
    font-family: "Inter Regular";
    padding-block-start: 1em;
    padding-block-end: 1em;
}

.firstVisitTitle {
    font-size: 50px;
    font-family: "Livvic Bold";
    color: #2B2BCB;
}

.section {
    /* aspect-ratio: 1/1; */
    border-radius: 15px;
    margin-inline-end: 0.7em;
    margin-inline-start: 0.7em;
    background-color: #F6F4F1;
    text-align: center;
    height: 420px;
    width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.sectionContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.flexSpacer {
    flex-grow: 1;
    /* Este elemento crecerá para llenar el espacio disponible y empujar el texto hacia abajo */
}

.textWrapper {
    width: 100%;
    text-align: center;
    margin-top: auto;
    height: 70px;
    display: flex;
    justify-content: center;
}

.uploadImageCopy {
    color: #89898B;
    font-size: 0.7rem;
    width: 80%;
    font-family: 'Inter Medium';
}

.mode {
    font-family: "Inter Regular";
    color: #FF6563;
    font-size: 1.6rem;
}

.tool {
    font-family: "Inter Regular";
    padding-block: 1em;
    width: 80%;
    font-size: 0.9rem;
}

.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-family: 'Inter Regular';
    margin-bottom: 16px;
    margin-top: 12px;
    font-size: 1.75rem;
}

.info {
    font-family: 'Inter Regular';
    color: #292929;
    font-size: 0.75rem;
}

/* .dropzoneRow {
    min-height: 350px;
    border: solid 1px #e2e1e1;
    border-radius: 20px;
} */

.canvasTop {
    width: 85%;
}

.imagesDots {
    text-align: center;
}

.btn {
    position: relative;
    font-family: 'Inter Regular';
    background-color: #FF6563;
    border-color: #FF6563;
    text-transform: uppercase;
    padding-block: 0.7em;
    border-radius: 10px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
}

.btn:hover::before {
    border: 1px solid #FF6563;
}

.btn::before {
    position: absolute;
    content: "";
    right: 2rem;
    border: 1px solid white;
    height: 80%;
}

.btn::after {
    margin-left: 1.5rem;
    border-top: .4em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
    border-bottom: 0;
}

.btn:hover {
    background-color: #ffffff;
    color: #FF6563;
    border-color: #FF6563;
}

.mobileCopy {
    background-color: #F16553;
    font-family: 'Inter Medium';
    color: #ffffff;
    text-align: center;
    padding-inline: 2em;
    padding-block: 1em;
}

.confirmRestartBtnContainer {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
}

.modalHelpContainer {
    padding: 1rem;
}

.modalHelpContent p {
    font-size: 20px;
}

.colorPickerCol,
.cardsCol {
    margin-top: 3rem;
}

.colorPickerCol {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirmHelpBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 80%;
    border: 1px solid #FF6563;
    background-color: #FF6563;
    color: #ffffff;
    box-shadow: 0px 2px 4px 0px #00000026;
    border-radius: 10px;
    font-family: 'Inter Medium';
    font-size: 10pt;
    padding: 20px;
}

.confirmHelpBtn:hover {
    background-color: #2B2BCB;
    border-color: #2B2BCB;
}

.helpModalConfirmTxt {
    margin-top: .5rem;
    margin-bottom: .5rem;
    font-family: 'Inter Medium';
}

.actionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */
    height: 33px;
    border: 1px solid #D9D9D9;
    background-color: #ffffff;
    color: #000000;
    box-shadow: 0px 2px 4px 0px #00000026;
    border-radius: 10px;
    font-family: 'Inter Medium';
    font-size: 10pt;
}

.actionBtn svg:first-child {
    margin-right: .3rem;
}

.actionBtn:hover {
    background-color: #000000;
    border-color: #000000;
}

.actionBtnConfirm {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 33px;
    border: 1px solid #2C2C2C;
    background-color: #2C2C2C;
    color: #ffffff;
    box-shadow: 0px 2px 4px 0px #00000026;
    border-radius: 10px;
    font-family: 'Inter Medium';
    font-size: 10pt;
}

.actionBtnConfirm:hover {
    background-color: #ffffff;
    color: #2C2C2C;
    border-color: #2C2C2C;
}

.versionTxt {
    font-size: 10px;
    color: #A1A1A1;
}

.bannerMobileBestExperience {
    text-align: center;
    background-color: #808080;
    color: #ffffff;
    padding: .6rem;
    font-family: 'Inter Medium';
    font-size: 14px;
}

.modalConfirmRestart p {
    font-family: "Inter Regular";
}

@media(width < 500px) {
    .confirmHelpBtn {
        width: 100%;
    }
}


@media (min-width: 992px) {
    .heading {
        align-items: start;
        padding: 0;
    }

    .exportCol {
        justify-content: flex-end;
        align-items: flex-end;
        padding: 0;
    }

    .colorPickerCol {
        padding-inline-start: 8%;
    }
}

@media (max-width: 576px) {

    .title,
    .info {
        text-align: center;
    }

    .section {
        width: 90%;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }

    .imagesDots {
        margin-top: 5rem;
    }

    .colorPickerContainer {
        margin-bottom: 6rem;
        justify-content: center;
    }

    .colorPickerCol {
        margin-top: 1rem;
    }
}

/* TABLET */
@media (min-width: 768px) and (max-width: 992px) {
    .section {
        margin-block-start: 1em;
        margin-block-end: 1em;
    }

    .colorPickerCol {
        margin-bottom: 2rem;
    }
}

@media (width < 768px) {
    .colorPickerContainer {
        justify-content: center;
    }
}

/* @media (width > 992px) {
    .cardsCol{
        padding-inline-end: 7.5%;
    }
} */

@media (width >=1200px) {
    .colorPickerCol {
        padding-left: 7rem;
    }
}