@import "../../index.css";

.searchBanner {
    min-height: 150px;
    background-color: #FF6563;
}

.searchBanner h1,
.searchBanner p {
    color: white;
    text-align: center;
}

.searchBanner h1 {
    font-size: 50px;
    font-family: "Livvic Bold";
}

.searchBanner p {
    font-size: 22px;
    font-family: 'Inter Regular';
}

.searchBar {
    border-right: none !important;
}

.searchBar:focus {
    border-color: #ffffff;
    box-shadow: none;
}

.searchBarLabel,
.resultsCount {
    font-family: 'Inter Medium';
    font-size: 16px;
}

.searchBar::placeholder {
    font-size: 16px;
    color: #A1A1A1;
}

.searchBtn {
    background-color: #FF6563;
    color: white;
    border-radius: 10px;
    padding-inline: 2.5rem;
    margin-inline: .5rem;
    font-family: 'Inter Regular';
}

.searchBtn:last-of-type {
    padding-inline: .5rem !important;
    margin-left: 0;
}

.searchBtn:hover {
    background-color: #2B2BCB;
    color: white;
}

.searchBtn:active {
    background-color: #3b3bff !important;
    color: white !important;
}

.filters {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33px;
    border: 1px solid #f1f1f1;
    color: #2B2BCB;
    border-radius: 0;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 18px 14px;
}

.filters svg:first-child {
    margin-left: .3rem;
}

.filters:hover,
.filters:active,
.filters:focus {
    color: #2B2BCB;
}

.filters svg {
    font-size: 14px;
}

.clearFilters {
    background: none;
    border: none;
    color: #2B2BCB;
}

.searchBottomBanner {
    background-color: #F8F6FA;
}

.sponsorsTitle {
    color: #A1A1A1;
    font-size: 18px;
    font-family: "Inter Medium";
    text-align: center;
}

.bottomBannerText h2,
.bottomBannerText p {
    color: #2B2BCB;
}

.bottomBannerText h2 {
    font-family: "Livvic Bold";
}

.bottomBannerText p {
    font-family: "Livvic Medium";
}

.bottomBannerText h1 {
    font-size: 40px;
}

.bottomBannerText p {
    font-size: 18px;
}

.footerBannerBtn {
    font-family: 'Inter Medium';
    font-size: .8rem;
    background-color: #FF6563;
    border: 2px solid #2B2BCB;
    color: whitesmoke;
    width: 200px;
}

.footerBannerBtn:active {
    background-color: #e14938 !important;
}

.footerBannerBtn:hover {
    background-color: #ff6f5f;
}

.announce {
    margin-top: 1rem;
    font-size: 10px;
    color: #A1A1A1;
}

.sponsorImage {
    opacity: 0.5;
}

@media (width < 576px) {
    .filters {
        font-size: 9px;
    }

    .filters svg {
        font-size: 9px;
    }
}

@media (width < 768px) {
    .bottomBannerText {
        text-align: center;
    }
}

@media (width < 992px) {
    .searchBtn {
        padding-inline: .5rem;
    }
}