.footer {
    background-color: #000000;
}

.container {
    width: 100%;
}

.firstHalf {
    display: flex;
    flex-direction: column;
}

.links {
    font-size: 12px !important;
    font-family: 'Inter Regular';
    text-decoration: none;
    color: whitesmoke;
}

.copyright {
    color: #d9d9d9;
    font-size: 10px;
    font-family: 'Inter Regular';
}

.media {
    display: flex;
    /* justify-content: space-between; */
}

@media (width >=768px) {
    .links {
        font-size: 1rem
    }

    .mediaIcons {
        font-size: 1.5rem;
    }
}