.exportBtn {
    border-radius: 5px;
    background-color: #FF6563;
    border: none;
    font-size: 12px;
    font-family: 'Inter Medium';
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 0;
    padding-right: 0;
    height: 34px;
}

.sharedPaletteExportBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 33px;
    border: 1px solid #D9D9D9;
    background-color: #ffffff;
    color: #000000 !important;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 18px 14px;
}

.sharedPaletteExportBtn:hover {
    background-color: #000000;
    color: #ffffff !important;
}

.exportBtn svg,
.sharedPaletteExportBtn svg {
    margin-right: .3rem;
}

.downloadLink {
    text-decoration: none;
    color: white;
    height: 34px;
    width: 100%;
    padding-top: 8px;
}

.sharedPaletteDownloadLink {
    text-decoration: none;
    color: #000000;
}

.textWhite {
    color: #ffffff;
}

@media (width < 442px) {
    .exportBtn {
        margin-top: 14px !important;
    }
}