@import "../../../index.css";

.actionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */
    height: auto;
    border: 1px solid #D9D9D9;
    background-color: transparent;
    color: #ffffff;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 10px 14px;
}

.actionBtn svg:first-child {
    font-size: 14px;
    margin-right: .3rem;
}

.actionBtn:hover {
    background-color: #000000;
}

.actionBtn:disabled {
    background-color: transparent;
    color: #A1A1A1;
    border-color: #A1A1A1
}

.onTopBtn {
    position: relative;
    z-index: 1032;
}

.inputLabel {
    font-size: 12px;
    font-family: 'Inter Medium';
}

.saveAsLabel {
    color: #FF6563;
    font-size: 12px;
    font-family: 'Inter Medium';
}

.saveBtn {
    border-radius: 5px;
    background-color: #FF6563;
    border: none;
    font-size: 12px;
    font-family: 'Inter Medium';
    /* display: flex; */
    align-items: center;
    width: 100%;
}

.saveBtn svg {
    margin-right: .3rem;
}

.saveOption {
    font-family: 'Inter Medium';
    font-size: 12px;
    width: 100%;
    border-radius: 0;
    background: none;
    text-align: start;
    border: none;
    padding: .8rem;
}

.saveBtnCol {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.returnBtn {
    padding-block: 0;
    background: none;
    color: #000000;
    border: none;
    margin-bottom: 1rem;
}

.returnBtn:hover {
    background: none;
    border: none;
    color: #FF6563;
}

.returnBtn:active {
    background: none !important;
    color: #85342b !important;
}