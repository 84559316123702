@import "../../index.css";

/* .actionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33px;
    border: 1px solid #D9D9D9;
    background-color: transparent;
    color: #000000;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 18px 14px;
}

.actionBtn svg:first-child {
    margin-right: .3rem;
}

.filterDropdownBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 33px;
    border: 1px solid #000000;
    background-color: #000000;
    color: #ffffff;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 18px 14px;
    width: auto;
}

.actionBtn:hover,
.actionBtn:active,
.actionBtn:focus {
    background-color: #000000 !important;
    color: #ffffff;
}

.actionBtn svg {
    font-size: 14px;
}
 */
.dropdownMenu {
    max-height: 40vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
    font-family: 'Inter Medium';
    font-size: 13px;
}

/* @media (width >=768px) {
    .actionBtn {
        justify-content: space-around;
    }
} */