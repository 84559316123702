@import "../../../index.css";

.directQuote {
    background-color: #2B2BCB;
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.authorsRow {
    margin-inline: 6rem;
}

.authorsCol {
    height: 300px;
    padding: 0 2.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.authorsText {
    color: white;
    font-family: 'Livvic Medium';
}

@media(width >=992px) {
    .authorsCol {
        height: 500px;
    }

    .authorsText {
        font-size: 20px;
    }

}