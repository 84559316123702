.sidebarTop {
    margin-block: 2rem;
}

.sidebarTopExpanded {
    margin-right: 1rem;
    align-self: flex-end;
}

.sidebar {
    width: 6%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #c8c8c8;
    border-top: none;
    transition: width 1s ease-in-out;
    overflow: hidden;
}

.links {
    font-size: 16px;
    text-decoration: none;
    color: black;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
}

.links svg,
.activeLink svg {
    margin-right: 6px;
}

.links:hover {
    filter: invert(75%) sepia(90%) saturate(1400%) hue-rotate(-50deg) brightness(100%) contrast(100%);
}

.activeLink {
    filter: invert(75%) sepia(90%) saturate(1400%) hue-rotate(-50deg) brightness(100%) contrast(100%);
    font-size: 16px;
    text-decoration: none;
    color: black;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
}

.expanded {
    width: 12rem;
    align-items: flex-start;
}

.expandedLink {
    padding: 1rem 1rem;
}

.expandedActiveLink {
    display: flex;
    justify-content: space-between;
}