.mobileTopBarCommandsContainer {
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    margin-bottom: 1rem;
    padding-top: 6px;
    padding-bottom: 6px;
    width: 90%;
}

.mobileTopBarCommandsBtn {
    background: none;
    color: #000000;
    border: none;
    font-size: 12px;
}

.mobileTopBarCommandsBtn:disabled {
    background: none;
    color: #D9D9D9;
}

.mobileTopBarAddBtn {
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 12px;
}

.prevNextContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
}

.rangeBar {
    height: 6px;
}

/* en chrome y safari */
.rangeBar::-webkit-slider-runnable-track {
    height: .4rem;
}

/* en firebox */
.rangeBar::-moz-range-track {
    height: .4rem;
}

/* en Internet Explorer */
.rangeBar::-ms-track {
    height: .4rem;

}