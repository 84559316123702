.cardImage {
    height: 100%;
    width: 100%;
    border-radius: 0;
}

.cardTitle {
    border-radius: 2px;
}

.cardBtn {
    height: 40px !important;
}