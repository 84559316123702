.burguerMenu {
    width: 2rem;
    height: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: solid 1px #ced4da;
    border-radius: 0.25rem;
    /* background-color: #f8f9fa; */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.075);
}

.burguer {
    width: 70%;
    height: 0.2rem;
    background-color: #495057;
    border-radius: 15px;
    transition: transform 0.5s ease-out, background-color 0.3s ease;
}

.burguer:not(:last-child) {
    margin-bottom: 0.35rem;
}

.burguer.clicked:nth-of-type(1) {
    transform: rotate(45deg) translate(0.4rem, 0.4rem);
}

.burguer.clicked:nth-of-type(2) {
    transform: scale(0);
}

.burguer.clicked:nth-of-type(3) {
    transform: rotate(-45deg) translate(0.4rem, -0.4rem);
}