.carouselContainer {
    overflow: hidden;
    display: flex;
    align-items: center;
}

.carouselContent {
    display: flex;
    animation: scroll 30s linear infinite;
}

.carouselItem {
    flex: 0 0 auto;
    width: 120px;
    /* Ajusta el ancho según sea necesario */
    margin: 10px;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}