.imageContainer {
    height: auto;
    width: 100%;
    text-align: center;
}

.imagesContainer {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}

.images {
    height: auto;
    width: 45%;
}

.image {
    height: auto;
    width: 75%;
}

.cardsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 30px;
    margin-top: 18px;
    justify-content: flex-start;
}

.cardsContainerPreference {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cards {
    width: calc(20% - 10px);
    margin: 10px;
    box-sizing: border-box;
}

.cardsPreference {
    width: calc(26% - 20px);
    margin-inline-start: 10px;
    margin-inline-end: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.title {
    font-family: "Poppins";
    text-transform: uppercase;
    margin-top: 8px;
    font-size: 8px;
    line-height: 1;
    word-break: break-all;
    text-align: left;
}

.titlePreference {
    font-family: "Poppins";
    text-transform: "uppercase";
    margin-top: 8px;
    font-size: 8px;
    line-height: 1;
    word-break: break-all;
    text-align: left;
    max-width: 80px;
}

.actionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 33px;
    border: 1px solid #D9D9D9;
    background-color: #ffffff;
    color: #000000;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 18px 14px;
}

.actionBtn:hover {
    background-color: #000000;
}

.actionBtn:disabled {
    background-color: #ffffff;
    color: #A1A1A1;
    border-color: #A1A1A1
}

.infoContainer {
    border: solid 1px #e2e1e1;
    margin: 0 .5rem;
}

.expiredMessage {
    font-family: 'Inter Medium';
    font-size: 2rem;
    text-align: center;
    margin-top: 2.5rem;
}

.sharedPaletteTitle {
    font-family: 'Inter Medium';
    font-size: 2rem;
}

.paletteUserInfo {
    font-size: .9em;
    color: #686767;
    font-family: 'Inter Medium';
    margin-bottom: 0.3rem;
}

/* .paletteSharedByInfo{
    color: #000000;
} */

.sharedPaletteToolBarContainer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: .2rem solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 6px -5px rgba(0, 0, 0, 0.1);
}

@media (width < 576px) {
    .sharedPaletteTypeRowContainer {
        justify-content: center;
    }

    .colorCardsContainer {
        margin-top: 2rem;
    }
}