.header button {
    align-self: flex-start;
}

.headerViewDetails {
    background-color: #F4F7FB;
    border-bottom: none;
}

.modalTitle {
    font-family: 'Inter Regular';
}

.modalTitleViewDetails {
    font-family: 'Inter Medium';
    font-size: 1.3rem;
    margin-left: 1rem;
}

.addModal .header,
.addModal .body,
.addModal .footer {
    background-color: #F4F7FB;
}

.viewDetailsModal {
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;
}