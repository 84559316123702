.modal {
    align-content: center;
    margin: auto;
}

.modalTitle {
    font-family: 'Inter Regular';
}

/* Balloons details */

.detailTitles {
    font-family: 'Inter Regular';
}

.detailTexts {
    font-family: 'Heldane';
    margin-bottom: 0;
}

.deleteBalloonContainer {
    justify-content: right;
}

.confirmDeleteContainer {
    font-family: 'Heldane';
    margin-top: 1rem;
    justify-content: right;
}

.confirmDeleteContainer button {
    font-family: 'Inter Regular';
}

.hexColor {
    width: 260px;
    height: 30px;
}

.hexColorTxt {
    margin-top: 0.5rem;
    font-family: 'Inter Regular';
}

.balloonDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.deleteAlert {
    font-size: .8rem;
}

.hexColorCircle {
    height: 20px;
    display: inline-block;
    width: 20px;
    margin-left: 0.5rem;
    margin-bottom: -4px;
    border-radius: 10px;
}