.displayName {
    display: block;
    font-size: 0.7rem;
    color: #6A6A6A;
}

.optionRow {
    border-bottom: 1px solid #d9d9d9;
}

.selectedOption {
    background-color: #FFD6D2;
    /* border-radius: 15px; */
    display: flex;
    padding: 5px 10px;
    margin: .5rem;
    color: #ff6563;
    font-family: 'Inter Medium';
    font-size: 12px;
    border: 1px solid #f1f1f1;
}

.deleteBtn {
    padding: 0;
    margin: 0 0 0 .5rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    color: #ff6563;
}

.deleteBtn:hover {
    background: none;
}

.deleteBtn:active {
    background: none !important;
}