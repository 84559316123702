@media (max-width: 576px) {
    .imageContainer {
        text-align: center;
        margin-inline-start: 1.2rem;
        margin-inline-end: 1.2rem;
    }

}

@media (max-width: 991px) {
    .imageContainer {
        text-align: center;
        background-color: #F7F7F7 ;
        border: 1px solid #D7D7D7;
        padding: 8px;
    }
}