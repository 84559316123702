.canvas {
    max-width: 100%;
    max-height: 80vh;
    height: auto;
    border-radius: 20px;
    cursor: none;
    touch-action: none;
}

.exportCol {
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageBrightnessContainer {
    width: 40%;
    justify-content: center;
    margin-left: 19%;
    text-align: center;
    margin-block: 0.5em;
}

.colorCircleContainer {
    position: absolute;
    right: -50px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.canvasDesktopContainer {
    display: flex;
    align-items: center;
}

@media (min-width: 992px) {
    .canvas {
        /*  max-width: 90%;
        max-height: 70vh; */
        width: auto;
    }
}

@media (max-width: 1400px) {

    .imageBrightnessContainer {
        margin-left: 25%;
    }
}

@media (max-width: 576px) {
    .imageContainer {
        margin: 1px;
        text-align: center;
    }

    .canvas {
        border-radius: 0px;
        /* padding: 8px; */
        touch-action: none;
        max-height: 50vh;
    }
}

@media (max-width: 991px) {
    .exportCol {
        margin-block: 1rem;
    }

    .imageContainer {
        margin: 1px;
        text-align: center;
    }
}

/*---------------------------- ZoomCanvas stye ------------------------------- */

.zoomCircle {
    pointer-events: none;
}

/* .zoomCircle::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 4px;
    background-color: white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #6b6b6b;
} */
.zoomCircle::after,
.zoomCircle::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
}

.zoomCircle::after {
    width: 1rem;
    /* Ancho de la línea horizontal */
    height: 1px;
    /* Altura de la línea horizontal */
}

.zoomCircle::before {
    width: 1px;
    /* Ancho de la línea vertical */
    height: 1rem;
    /* Altura de la línea vertical */
}

.draggableCircle::after,
.draggableCircle::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
}

.draggableCircle::after {
    width: 1.5rem;
    /* Ancho de la línea horizontal */
    height: 1px;
    /* Altura de la línea horizontal */
}

.draggableCircle::before {
    width: 1px;
    /* Ancho de la línea vertical */
    height: 1.5rem;
    /* Altura de la línea vertical */
}

.squareDragColor {
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: 'Inter Medium';
    font-size: .8rem;
    margin-bottom: -3rem;
    /* margin-top: -2rem; */
    border: 2px solid #ffffff;
    width: 70px;
    height: 70px;
    border-radius: 50px;
    box-shadow: 0px 0px 5px 2px #0000001A;
}

.matchingTooltip {
    font-size: 12px;
    text-align: left !important;
}

.matchingTooltipText,
.matchingTooltipTitle {
    margin-bottom: 0;
}

.matchingTooltipTitle {
    font-weight: bold;
    margin-bottom: 6px;
}

.matchingTooltipBtn {
    background-color: #FF6563;
    border-color: #FF6563;
    font-size: 12px;
}

.matchingTooltipBtn:focus,
.matchingTooltipBtn:active {
    background-color: #FF6563 !important;
    border-color: #FF6563 !important;
}