@import "../../../index.css";

.card {
    border: none;
}

.cardImg {
    border-radius: 0;
}

.cardTitle {
    font-family: 'Inter Medium';
    font-size: 12px;
}

.cardText {
    font-family: 'Inter Regular';
    font-size: 12px;
    color: #a9a9a9;
    margin-bottom: 15px !important;
}

.cardBtn {
    font-family: 'Inter Medium';
    width: 100%;
    border: solid 1px #d9d9d9;
    border-radius: 0;
    font-size: 10px;
    margin-block: .1rem;
    background-color: white;
}

.alert {
    position: fixed;
    bottom: 2rem;
    width: clamp(300px, 50%, 500px);
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border: 1px solid #000000;
    font-family: 'Inter Medium';
}

.actionBtnUpgrade {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FF6563;
    background-color: #FF6563;
    color: #ffffff;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 16px;
    width: 100%;
}

.actionBtnUpgrade:hover {
    background-color: #ffffff;
    color: #FF6563;
    border: 1px solid #FF6563;
}


.listColorTitle {
    font-size: 1.3rem;
}