@import "../../index.css";

.container {
    padding: 1rem;
    background-color: #f0f0f0;
    border-radius: 15px;
    /* max-width: 50rem; */
}

.datagrid {
    border: 1px solid #e0e0e0;
    padding: 0;
    margin: 0;
}

.table {
    background-color: white;
    width: 100%;
}

.headers,
.cells {
    padding: .3rem;
    border-bottom: 1px solid #dfdfdf;
}

.headers {
    font-family: 'Inter Regular';
    font-size: 12px;
}

.cells {
    font-size: 13px;
}

.cells:first-child {
    text-align: center;
}

.pageSizeText {
    color: gray;
    margin-right: 1rem;
    font-size: 12px;
}

.pageSize {
    border: none;
    padding: 0;
    width: 3rem;
    cursor: pointer;
}

.rowsOfTotal {
    color: gray;
    font-size: 12px;
}

.paginationBtn,
.paginationBtn:disabled,
.paginationBtn:active {
    background: none !important;
    color: rgb(98, 98, 98);
    border: none;
}

.paginationBtn:hover {
    border-radius: 15px;
    color: gray;
    background: rgb(231, 231, 231) !important;
}