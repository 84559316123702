.title {
    margin: 1rem 2rem 0 0;
    font-family: 'Inter Medium';
}

.infoContainer {
    /* border: solid 1px #e2e1e1; */
    margin: 0 .5rem;
    position: relative;
}

.cardRow {
    background-color: #ffffff;
}

/* .infoContainer .cardRow {
    border-bottom: solid 1px #e4e4e4;
} */

.infoContainer .cardRow:last-child {
    border-bottom: none;
}

.cardRow:first-of-type {
    margin-top: 0;
    filter: blur(0);
}

.cardRow {
    padding-inline-start: .5rem;
    padding-inline-end: .5rem;
    padding-block-start: .5rem;
    padding-block-end: .5rem;
    box-shadow: 0px 4px 4px 0px #00000014;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.bluredRow {
    filter: blur(5px);
    pointer-events: none;
}

.autocompleteCardRow {
    background-color: #ffffff;
}


/* .dataCols> :nth-child(-n+3) {
    margin-top: 1rem;
} */

.tableTitle {
    font-family: 'Inter Medium';
    font-size: 10px;
    color: #898989;
    text-transform: uppercase;
}

.secondaryTitle {
    font-family: 'Inter Regular';
    font-size: 10px;
}

.displayName {
    font-family: 'Inter Regular';
    font-size: 12px;
    white-space: normal;
    text-transform: uppercase;
    margin-top: 6px;
    margin-bottom: 0;
    line-height: 1.2;
}

.data {
    font-family: 'Inter Medium';
    font-size: 13px;
    white-space: normal;
}

.dataBrandColorName {
    font-family: 'Inter Medium';
    font-size: 13px;
    white-space: normal;
}

.dataSizes {
    font-family: 'Inter Medium';
    font-size: 13px;
    white-space: normal;
    background-color: #EDEDED;
    padding: 6px;
    border-radius: 4px;
    margin-bottom: 16px;
}

.actionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */
    height: 33px;
    border: 1px solid #FF6563;
    background-color: #FF6563;
    color: #ffffff;
    /* box-shadow: 0px 2px 4px 0px #00000026; */
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    margin-top: 6px;
    /* width: 130px; */
}

.actionBtn svg:first-child {
    margin-right: .3rem;
}

.actionBtn:hover {
    background-color: #000000;
}

.actionBtn:disabled {
    background-color: #ffffff;
    color: #A1A1A1;
    border-color: #A1A1A1
}

.actionBtn svg:first-child {
    margin-right: .3rem;
}

.infoTooltip {
    font-size: 12px;
}

.secondaryColorsTitle {
    margin-left: 0.5rem;
    font-family: 'Inter Medium';
    color: #A1A1A1;
    font-style: italic;
}

.versionTxt {
    font-size: 10px;
    color: #A1A1A1;
}

.listColorTitle {
    font-size: 1.3rem;
}

.filterRow {
    padding-right: .7rem;
}

.sizesMobile {
    padding-left: .2rem;
    padding-right: .2rem;
}

.sizesDesktop {
    height: 35px;
    padding-left: .2rem;
    padding-right: .2rem;
}

.alert {
    position: fixed;
    bottom: 2rem;
    width: clamp(300px, 50%, 500px);
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border: 1px solid #000000;
    font-family: 'Inter Medium';
}

.actionBtnUpgrade {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FF6563;
    background-color: #FF6563;
    color: #ffffff;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 16px;
    width: 100%;
}

.actionBtnUpgrade:hover {
    background-color: #ffffff;
    color: #FF6563;
    border: 1px solid #FF6563;
}


@media (width < 576px) {

    /* .popupModal{
        width: 95%;
    } */
    .actionBtn {
        width: 100%;
    }
}



@media (width >=1200px) {
    .tableTitle {
        font-size: 12px;
    }
}



/* --------------------------------------      ReusableModal      --------------------------------------- */

.modalBody {
    margin: 0 0 2rem 0;
    padding: 0;
}

.noBalloons {
    margin: 1rem 0;
    color: #989898;
    text-align: center;
}