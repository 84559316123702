@import "../../index.css";

.homeContainer {
    height: 100vh;
}

/* ------ Fisrt Section ------ */
.homeSec1 {
    background-color: #F8F6FA;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.homeIntro {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homePreTitle {
    font-size: 12px;
    font-family: 'Livvic Bold';
    text-align: center;
    color: #FF8CD1;
}

.homeTitle {
    font-size: 30px;
    font-family: 'Livvic Bold' !important;
    text-align: center;
    color: #2B2BCB;
}

.homeSubtitle {
    font-size: 16px;
    text-align: center;
    font-family: 'Inter Medium';
    margin: 1rem 0;
    color: #2B2BCB;
}

.heroImgContainer img {
    border: 1px solid #2B1B9A;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
}

.homeBtn {
    font-size: 14px;
    font-family: 'Inter Medium';
    background-color: #FF8CD1;
    border: 2px solid #2B2BCB;
    color: whitesmoke;
    padding-top: .7rem;
    padding-bottom: .7rem;
}

.homeBtn:active {
    background-color: #fc61be !important;
}

.homeBtn:hover {
    background-color: #2B2BCB;
}

/* ------ Second Section ------ */
.homeSec2 {
    background-color: #FFFFFF;
}

.homeSec2Title {
    font-size: 20px;
    font-family: 'Livvic Bold';
    text-align: center;
    color: #2B2BCB;
}

/* ------ Third Section ------ */
.homeSec3 {
    background-color: #FFF8F8;
    padding-top: 5rem;
}

.homeSec3Title {
    font-size: 30px;
    font-family: 'Livvic Bold';
    text-align: center;
    color: #2B2BCB;
}

.homeSec3Subtitle {
    font-size: 16px;
    text-align: center;
    font-family: 'Inter Medium';
    margin: 1rem 0;
    color: #2B2BCB;
}

.homeCard {
    background-color: #FF6563;
    /* min-height: 200px; */
    height: 250px;
    padding-top: 3rem;
    padding-bottom: 1rem;
    /* display: grid; */
    /* place-content: center; */
}

.homeCardTitle {
    width: 105px;
    margin: 0 auto;
}

.homeCardTitleShort {
    width: 95px;
    margin: 0 auto;
}

.homeCardTitle,
.homeCardTitleShort,
.homeCardText {
    color: white;
    font-size: 14px;

}

.homeCardImg {
    width: 10%;
    margin: auto;
}

.homeCardTitle,
.homeCardTitleShort {
    font-family: 'Livvic Medium';
}

.homeCardText {
    font-family: 'Inter Regular';
}

/* ------ Fourth Section ------ */
.homeSec4 {
    background-color: #F8F6FA;
}

.homeIntro4 {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding-left: 2.7rem;
    padding-right: 2.7rem;
}

.homeSecCta {
    background-color: #FFFFFF;
    height: 50vh;
}

.homeSec4Btn {
    font-size: 14px;
    font-family: 'Inter Medium';
    background-color: #FF6563;
    border: 2px solid #2B2BCB;
    color: whitesmoke;
    padding-top: .7rem;
    padding-bottom: .7rem;
}

.homeSec4Btn:hover {
    background-color: #2B2BCB;
}

/* ------ Fifth Section ------ */
.announce {
    margin-top: .5rem;
    font-size: 10px;
    font-family: "Inter Medium";
}

.homeJoinUs {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

@media (width >=576px) {
    /* .homeCard {
        height: 250px;
        } */

    .homeCardImg {
        width: 15%;
    }
}

@media (width <=768px) {
    .exploreLink {
        text-align: center;
    }
}

@media (width >=768px) {

    .homeTitle,
    .homeSubtitle {
        text-align: start;
    }

    .homeSec3Subtitle {
        width: 60%;
    }

    .homeIntro {
        align-items: flex-start;
    }

    .homeBtn,
    .homeSec4Btn {
        padding-inline: 3rem;
    }
}

@media (width >=992px) {
    .homePreTitle {
        font-size: 15px;
    }

    .homeTitle {
        font-size: 50px;
    }

    .homeSubtitle {
        font-size: 22px;
        padding-right: 8rem;
    }

    .homeSec2Title {
        font-size: 24px;
    }

    .homeSec3Title {
        font-size: 50px;
    }

    .homeSec3Subtitle {
        font-size: 22px;
    }
}