/* PRO VERSION MODAL */

.modalUpgradeProContainer {
    padding-right: 0;
}

.planBodyText {
    font-family: 'Inter Medium';
}

.actionBtnUpgrade {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 33px;
    border: 1px solid #FF6563;
    background-color: #FF6563;
    color: #ffffff;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 16px;
    padding: 18px 14px;
    width: 75%;
    justify-content: center;
}

.actionBtnUpgrade:hover {
    background-color: #ffffff;
    color: #FF6563;
    border: 1px solid #FF6563;
}

.modalUpgradeProFooter p {
    margin-bottom: .5rem !important;
    font-family: 'Inter Medium';
}

.cancelTxt {
    font-size: 12px;
    color: #00000080;
}

@media (width < 992px) {
    .modalUpgradeProContainer {
        padding-left: 0;
    }

    .modalUpgradeProTextCol {
        padding-top: 3rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .modalUpgradeProFooter {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .modalUpgradeToProImage {
        height: 200px;
        object-fit: cover;
    }

    .actionBtnUpgrade {
        width: 90%;
    }
}