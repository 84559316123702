@import "../../index.css";




/* 
.clarification {
    line-height: 20px;
    text-align: center;
}

.chooseBtn {
    font-family: 'Inter Regular';
    background-color: #FF6563;
    border-color: #FF6563;
    text-transform: uppercase;
    padding-block: 0.7em;
    border-radius: 10px;
    font-size: 0.8rem;
}

.chooseBtn:hover {
    background-color: #ffffff;
    color: #FF6563;
    border-color: #FF6563;
}

.actionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 33px;
    border: 1px solid #D9D9D9;
    background-color: #ffffff;
    color: #000000;
    border-radius: 4px;
    font-family: 'Inter Regular';
    font-size: 12px;
    padding: 18px 14px;
}

.actionBtn:disabled {
    background-color: #ffffff;
    color: #A1A1A1;
    border-color: #A1A1A1
}

.actionBtn:hover {
    background-color: #000000;
}

.actionBtn svg {
    margin-right: 0.2em;
}

.infoTooltip {
    font-size: 12px;
}


@media (max-width: 576px) {
    .dropzone {
        aspect-ratio: 1/1;
        border-radius: 0px;
        margin: 0 auto;
        width: 95%;
        }
        }
        
        @media (min-width: 576px) and (max-width: 991px) {
            .imageUploadedContainer {
                text-align: center;
                margin: 0 auto;
                }
    
    .imageUploadedContainer div {
        padding-inline: 0;
        }
        
        .dropzone {
        margin: 0 auto;
    }
    } 
    .addDropdownDropzone {
        background: none;
        justify-content: flex-start;
    }
    
    */

.mobileHelper {
    font-family: 'Inter Regular';
    margin-top: 1.2rem;
    color: #FF6563;
    font-size: 13px;
    text-align: center;
}

/* --------------- Dropzone without Image --------------- */
.dropzone {
    border: 2px dashed #D9D9D9;
    width: 100%;
    justify-content: center;
}

.helperTxtContainer {
    height: clamp(360px, 60vh, 450px);
    align-items: center;
    align-content: center;
}

.helperTxtCol {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.helperTxtTitle {
    font-family: 'Inter Regular';
    color: #000000;
    font-size: clamp(15px, 17px, 18px);
    width: fit-content;
    text-align: center;
}

.uploadBtn {
    background-color: #FF6563;
    color: #ffffff;
    border: 1px solid #FF6563;
    width: 280px;
    font-family: 'Inter Medium';
    font-size: 16px;
    padding-block: 1.2rem;
    border-radius: 2px;
}

.uploadBtn:hover {
    background-color: whitesmoke;
}

.helperTextSubtitle {
    color: #89898B;
    font-family: 'Inter Regular';
    font-size: 10px;
    text-align: center;
}