@import '../../../index.css';

@keyframes shake {
    0% {
        transform: rotate(0deg) translate(0, 0);
    }

    25% {
        transform: rotate(-2deg) translate(-2px, -2px);
    }

    50% {
        transform: rotate(2deg) translate(2px, 2px);
    }

    75% {
        transform: rotate(-2deg) translate(-2px, -2px);
    }

    100% {
        transform: rotate(0deg) translate(0, 0);
    }
}

.animatedCard {
    animation: shake 1s infinite;
}

.imgContainer {
    position: relative;
    width: 100%;
    /* Fallback para navegadores antiguos */
    padding-top: 80%;
    /* 4 / 5 = 0.8 */
    display: flex;
    align-items: center;
}

.fallbackAspectRatio {
    /* aspect-ratio: 4 / 5; */
    padding-top: 125%;
}

.img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.cardBody {
    justify-content: space-between;
    align-items: center;
}

.cardTitle {
    margin: 0;
    font-family: 'Inter Medium';
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;
    margin-top: 6px;
    /* min-height: 24px; */
}

.cardBrand {
    font-size: 11px;
    color: #A1A1A1;
    font-family: 'Inter Medium';
}

.detailsBtn {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 40px;
    border: 1px solid #D9D9D9;
    background-color: #ffffff;
    color: #000000;
    border-radius: 4px;
    font-size: 11px;
    font-family: 'Inter Medium';
    white-space: nowrap;
    /* padding: 18px 14px; */
    text-transform: uppercase;
}

.deleteBtn {
    font-size: 14px;
}

.detailsBtn:disabled {
    background-color: #ffffff;
    color: #A1A1A1;
    border-color: #A1A1A1
}

.moreInfoLink {
    position: absolute;
    top: 37%;
    left: 27%;
    color: #ffffff;
    font-family: 'Inter Regular';
    font-size: 14px;
    white-space: nowrap;
    padding: 0;
}

.moreInfoLink:hover {
    color: rgb(213, 213, 213);
    cursor: pointer;
}

@media (width >=992px) and (width < 1200px) {
    .detailsBtn {
        font-size: 10px;
    }

    .moreInfoLink {
        top: 32%;
        left: 10%;
        font-size: 11px;
    }

    .deleteBtn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-size: 13px;
    }

    .viewDetailsBtn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
        font-size: 9px !important;
    }
}

@media (width >=992px) and (width < 1400px) {

    .deleteBtn,
    .viewDetailsBtn {
        width: auto !important;
        border-right: 1px solid #D9D9D9;
    }
}

@media (width >=1200px) and (width < 1400px) {
    .deleteBtn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-size: 13px;
        width: auto !important;
    }

    .viewDetailsBtn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
        width: auto !important;
    }
}

@media (min-width: 1200px) {
    .moreInfoLink {
        left: 17%;
        top: 35%;
        font-size: 11px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .moreInfoLink {
        top: 41%;
        left: 24%;
        font-size: 16px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .moreInfoLink {
        top: 41%;
        left: 27%;
        font-size: 16px;
    }
}

@media (max-width: 375px) {
    .detailsBtn {
        font-size: 10px;
    }
}

@media (min-width: 1400px) {
    .moreInfoLink {
        left: 24%;
        top: 38%;
    }
}

@media (aspect-ratio: 4/5) {
    .imgContainer {
        padding-top: 0;
        /* Elimina el padding fallback si se soporta aspect-ratio */
    }
}