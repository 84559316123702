@import "../../index.css";

.contactUsContainer {
    background-color: white;
}

.contactUsTitle {
    color: #2B2BCB;
    font-size: 50px;
    font-family: "Livvic Bold";
}

.contactUsInfo {
    width: 70%;
}

.contactUsInfo,
.contactUsData {
    font-size: 16px;
    font-family: "Inter Medium";
}

.contactUsSubtitle {
    color: #FF6563;
    font-family: "Livvic Bold";
}

.imgCol img {
    height: calc(100vh - 64px - 48px);
    overflow: hidden;
    object-fit: cover;
}

@media (width < 768px) {
    .contactUsInfo {
        width: 100%;
    }

    .imgCol img {
        height: 50vh;
    }
}