@import "../../../index.css";

.actionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */
    height: auto;
    border: 1px solid #D9D9D9;
    background-color: transparent;
    color: #ffffff;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 10px 14px;
}

.actionBtn svg:first-child {
    font-size: 14px;
    margin-right: .3rem;
}

.actionBtn:hover {
    background-color: #000000;
}

.actionBtn:disabled {
    background-color: transparent;
    color: #A1A1A1;
    border-color: #A1A1A1
}

.onTopBtn {
    position: relative;
    z-index: 1032;
}

.optionsLabel {
    font-size: 13px;
    font-family: 'Inter Medium';
}

.downloadTextInputs::placeholder {
    font-size: 13px;
    color: #d5d2d2;
}

.downloadTextInputs {
    animation: slideDown 1s ease-out;
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
        /* Comienza fuera de la vista */
        opacity: 0;
        /* Opción para que aparezca con opacidad */
    }

    100% {
        transform: translateY(0);
        /* Llega a su posición original */
        opacity: 1;
        /* Opción para que esté completamente visible */
    }
}