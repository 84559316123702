@import "../../index.css";

.rowTitle {
    margin-top: 1rem;
    font-size: .7rem;
    font-family: 'Inter Regular';
}

.rowSubtitle {
    font-size: 10px;
    font-family: 'Inter Regular';
}

.rowData {
    font-size: .9rem;
    font-family: 'Inter Medium';
    white-space: normal;
}

.balloonOptionRow {
    justify-content: space-between;
    padding-right: 1rem;
}

.applyFiltersBtn {
    background-color: #000000;
    border-color: #000000;
    font-size: 13px;
}

.applyFiltersBtn:hover {
    background-color: #565e64;
    border-color: #565e64;
}

.applyFiltersBtn:active {
    background-color: #565e64 !important;
    border-color: #565e64 !important;
}