@font-face {
    font-family: 'Messina';
    src: url('../src/assets/fonts/MessinaSans-Bold.otf') format('opentype');
    font-weight: normal;
}

@font-face {
    font-family: 'Messina Sans Semi Bold';
    src: url('../src/assets/fonts/MessinaSansWeb-SemiBold.ttf') format('truetype');
}


@font-face {
    font-family: 'Heldane Italic';
    src: url('../src/assets/fonts/HeldaneText-RegularItalic.otf') format('opentype');
    font-weight: normal;
}

@font-face {
    font-family: 'Heldane';
    src: url('../src/assets/fonts/HeldaneText-Regular.otf') format('opentype');
    font-weight: normal;
}

@font-face {
    font-family: 'Inter Regular';
    src: url('../src/assets/fonts/Inter_24pt-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Inter Medium';
    src: url('../src/assets/fonts/Inter_24pt-Medium.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Livvic Bold';
    src: url('../src/assets/fonts/Livvic-Bold.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Livvic Medium';
    src: url('../src/assets/fonts/Livvic-Medium.ttf') format('truetype');
    font-weight: normal;
}

.main {
    padding-top: 64px;
}

.offcanvas-backdrop {
    width: 100%;
    height: 100%;
}

.nav-pills {
    --bs-nav-pills-link-active-bg: #FBAA9C;
}

.nav-pills .nav-link {
    border-radius: 0 !important;
}

/* .navbar-nav .dropdown-menu {
    left: auto !important;
} */

/* Apariencia de la barra de desplazamiento */
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 10px;
    /* Ancho de la barra */
}

/* Estilo del "thumb" (la parte que se arrastra) */
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
    background: #606060;
}

/* Desplazamiento suave en iOS */
.container {
    -webkit-overflow-scrolling: touch;
    /* Habilitar desplazamiento suave en iOS */
}

.modal-content {
    border: none
}

#filtering-autocomplete {
    max-height: 380px !important;
    background: #F4F7FB;
}

/* #filtering-autocomplete .row{
    background: #ffffff;
} */

.fluid_admin_routes {
    display: flex;
    min-height: calc(100vh - 72px);
}

/* COLOR DE RADIOS Y CHECKBOXES */

.form-check-input:checked {
    border-color: #FF6563 !important;
    background-color: #FF6563 !important;
}

.form-check-input {
    border: 1px solid #FF6563 !important;
}

@media (width < 992px) {
    .form-check label {
        font-size: 15px;
    }
}

@media (width >=992px) and (width <=1200px) {

    /* .modal-dialog {}*/
    .modal-xl {
        max-width: 95% !important;
    }
}

/* .CanvasWithZoom_matchingTooltip__eAdID .tooltip-inner{
    max-width: none !important;
    width: 280px !important;
} */

.offcanvas {
    z-index: 100000;
}

.modal {
    z-index: 100000;
}

/* ocultar icono por defecto dropdownBtn */
.no-default-icon::after {
    display: none !important;
}

/* ocultar icono dropddown boton add workspace desktop */
.no-default-icon svg:nth-of-type(2) {
    display: none !important;
}


[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
    z-index: 100000;
}

.list-group-item-action:active {
    background: none;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #000000;
    background: none;
}

/* Matching / Help tooltip */

#matching-tooltip .tooltip-inner,
#help-tooltip .tooltip-inner {
    text-align: left;
    background-color: #ffffff;
    color: #000000;
    padding-left: .6rem;
    padding-right: .6rem;
    padding-top: .8rem;
    padding-bottom: .8rem;
    max-width: 280px;
}

#help-tooltip .tooltip-inner {
    max-width: 270px;
}

#help-tooltip .tooltip-inner {
    max-width: 270px;
}

#matching-tooltip,
#help-tooltip {
    opacity: 1;
    max-width: 300px;
    box-shadow: 0px 0px 5px 2px #0000001A;
}

#matching-tooltip .tooltip-arrow::before {
    border-top-color: #ffffff;
}

#help-tooltip .tooltip-arrow::before {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
}

#help-tooltip {
    z-index: 1;
}

.rbt-menu-pagination-option {
    background-color: #000000;
    color: #ffffff;
}

.rbt-menu-pagination-option:hover {
    background-color: #eeeeee;
}

#view-details-modal .modal-header {
    padding-bottom: 0;
}

#view-details-modal .modal-body {
    padding-top: .7rem;
}

/* UPGRADE TO PRO MODAL */
#upgradeToProModal .modal-body {
    padding-right: 0;
    padding-top: 0;
    background-color: #F6F4F1;
}

#upgradeToProModal .modal-header {
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    background-color: #F6F4F1;
}

@media (width < 992px) {
    #upgradeToProModal .modal-body {
        padding-left: 0;
    }
}

[data-sonner-toast] [data-close-button="true"] {
    position: absolute;
    top: 50%;
    left: 93%;
    /* Ajusta según tu diseño */
    transform: translateY(-50%);
    /* Centrar verticalmente */
    z-index: 10;
    /* Asegura que esté por encima del contenido */
}