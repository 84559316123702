.container {
    padding: 0;
}

.title {
    font-size: 30px;
    font-family: 'Inter Regular';
    margin-bottom: 2rem;
}

.cardContainer {
    padding: 1rem;
    background-color: #f0f0f0;
    border-radius: 15px;
    border: none;
}

.modal {
    align-content: center;
    margin: auto;
}

.reloadBtn {
    margin-right: 1em;
}

.uploadBtn {
    background-color: #000000;
    border-color: #000000;
}

.viewDetailsBtn {
    margin-left: 7px;
}

/* USERS */
.cancelEditBtn {
    margin-right: 1rem;
}

/* HEX CALC */
.calculateBtn {
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 33px;
    border: 1px solid #FF6563;
    background-color: #FF6563;
    color: #ffffff;
    /* box-shadow: 0px 2px 4px 0px #00000026; */
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    margin-top: 6px;
    /* width: 130px; */
}

.calculateBtn:hover {
    background-color: #000000;
    border: 1px solid #000000;
}