.langSwitcherContainerFooter {
    display: flex;
    color: #ffffff;
    align-items: center;
}

.langSwitcherContainerHeader {
    display: flex;
    color: #000000;
    align-items: center;
}

.langSwitcherContainerMobile {
    display: flex;
    color: #000000;
}

.langSwitcherContainerMobile>svg:first-child {
    margin-top: 1.5rem;
    margin-right: 0.3rem;
}

.langSwitcherContainerFooter svg {
    margin-right: 0.2em;
}

.langSwitcherContainerHeader svg {
    margin-right: 0.1em;
}

.langSwitcherDropdown {
    font-size: 12px;
}

.langSwitcherDropdown .dropdown-menu {
    min-width: 50px !important;
}

.langSwitcherDropdownItem {
    padding-left: 0.7em;
}

.links {
    font-size: .9rem;
    text-decoration: none;
    color: #000000 !important;
    font-family: 'Inter Medium';
    line-height: 0.3rem;
}

.links a {
    color: #000000;
}

.userOptions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 35px;
}

.selectedLangCheck {
    margin-left: .7em;
    /* color: #3acd3a; */
    color: #FF6563;
}

.mobileLinks {
    font-size: 1.3rem;
    text-decoration: none;
    color: black;
    font-family: 'Inter Medium';
    line-height: 3rem;
    padding: 0;
    margin: 0;
    text-align: start !important;
}

.mobileDropdownLinks {
    font-size: 1.3rem;
    text-decoration: none;
    color: black;
    font-family: 'Inter Medium';
    line-height: 3rem;
    padding-left: 1rem;
    margin: 0;
    text-align: start !important;
}