@import "../../../index.css";

.actionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */
    height: 33px;
    border: 1px solid #D9D9D9;
    background-color: #ffffff;
    color: #000000;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 18px 14px;
}

.actionBtn svg:first-child{
    margin-right: .3rem;
}

.actionBtn:hover {
    background-color: #000000;
}

.actionBtn:disabled {
    background-color: #ffffff;
    color: #A1A1A1;
    border-color: #A1A1A1
}

.actionBtn svg:first-child {
    margin-right: .3rem;
}

.rangeBar {
    height: 6px;
}

/* en chrome y safari */
.rangeBar::-webkit-slider-runnable-track {
    height: .4rem;
}

/* en firebox */
.rangeBar::-moz-range-track {
    height: .4rem;
}

/* en Internet Explorer */
.rangeBar::-ms-track {
    height: .4rem;

}

.mobileCommands {
    background-color: #F2F2F2;
    z-index: 3;
}

.navRow {
    width: 100%;
}

.navRow>* {
    display: flex;
    justify-content: center;
}

.navRow>*>* {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Inter Medium';
    font-size: 12px;
    background: none;
    border: none;
    color: #000000;
}

.navRow>*>*:hover {
    background: none;
    color: #000000;
}

.navRow>*>*:active {
    background: none !important;
    color: #000000 !important;
}


.dropdownMore {
    color: #000000 !important;
    background: none !important;
    border: none !important;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    height: auto !important;
}

.dropdownMore::after {
    display: none !important;
}

.mobileCommandBtn {
    border: none;
    background-color: transparent;
    color: #000000;
}

.mainOption svg {
    margin-right: .7rem;
}

.mobileCommandBtn:disabled {
    background-color: transparent;
    color: #A1A1A1;
}

.onTopBtn {
    position: relative;
    z-index: 1032;
}

.topOption {
    border-bottom: 1px solid #D9D9D9 !important;
    display: flex;
}

.topOption:focus {
    background-color: #ffffff;
}

.mainOption,
.topOption {
    padding-block: 1rem;
    border: none;
    font-family: 'Inter Medium';
    font-size: 1rem;
}

.options {
    border: none;
    margin-left: 3rem;
    font-family: 'Inter Medium';
    font-size: 1rem;
    background: #ffffff !important;
    margin-bottom: 1rem;
}