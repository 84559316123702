@import "../../index.css";

.rowContainer {
    min-height: 100vh;
    background-color: #F8F6FA;
    font-family: 'Inter Medium';
    padding-block: 2rem;
}

.colContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.title {
    color: #2B2BCB;
    font-family: 'Livvic Bold';
    font-size: 50px;
    text-align: center;
}

.register {
    font-family: 'Inter Medium';
    display: block;
    text-decoration: none;
    font-size: 16px;
    color: #717272;
}

.agreeTermsCheck {
    font-family: 'Inter Medium';
    margin-block: 1rem;
    text-decoration: none;
    font-size: 12px;
    color: #717272;
}

.register:hover {
    color: #000000;
}

.registerSignupTxt {
    text-decoration: underline;
    font-family: "Inter Medium";
}

.card {
    width: 35%;
    border: none;
    background-color: transparent;
}

.formLabels,
.formInputs {
    color: #333333;
    font-size: 0.8rem;
}

.formInputs {
    padding-block: 1.2em;
    background-color: #E7E7E7;
}

.formInputs::placeholder {
    color: #9EA3A2;
}

.loginBtn {
    font-family: 'Inter Regular';
    background-color: #FF6563;
    border-color: #FF6563;
    width: 100%;
    text-transform: uppercase;
    padding-block: 0.7em;
    border-radius: 10px;
}

.loginBtn:hover {
    background-color: #ffffff;
    color: #FF6563;
    border-color: #FF6563;
}

.forgotPassLink {
    margin-top: 8px;
    display: block;
    color: #FBAA9C;
    font-size: 0.8rem;
    text-decoration: none;
}

.forgotPassLink span {
    text-decoration: none;
}

.goToLoginRegisterSuccess {
    text-decoration: none;
    font-size: 16px;
    margin-block: 1rem;
    color: #000000;
}

.goToLoginRegisterSuccess:hover {
    text-decoration: underline;
}

.loginBtn:active {
    background-color: #000000 !important;
    border-color: #000000 !important;
}

.loginBtn:focus {
    background-color: #000000 !important;
    border-color: #000000 !important;
}

/* .confirmPassword {
    position: absolute;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
} */

.confirmPasswordActive {
    position: relative;
    opacity: 1;
    transform: translateY(0);
}

.errorTxt {
    font-size: 14px;
    color: rgb(204, 0, 0);
    font-family: 'Heldane';
}

.loginStepsCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #CAE4D7;
}

.showPswBtn {
    background-color: #E7E7E7;
    border-color: #E7E7E7;
    color: #9EA3A2;
}

.showPswBtn:hover,
.showPswBtn:focus,
.showPswBtn:active {
    background-color: #E7E7E7;
    color: #000000;
    border-color: #E7E7E7;
}

.signupWithGoogleBtn {
    font-family: 'Inter Regular';
    background-color: #ffffff;
    color: #13141499;
    border-color: #D9D9D9;
    width: 100%;
    padding-block: 0.7em;
    border-radius: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.signupWithGoogleBtn:hover {
    background-color: #FF6563;
    color: #ffffff;
    border-color: #FF6563;
}

.signupWithGoogleBtn svg {
    margin-right: .5rem;
}

.btnRow {
    padding-left: .7rem;
    padding-right: .7rem;
}

.continueWithEmailBtn {
    text-transform: none;
}

@media (max-width: 768px) {
    .card {
        width: 80%;
    }
}

@media (min-width: 1400px) {
    .card {
        max-width: 55%;
    }
}