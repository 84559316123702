@import "../../../index.css";

.actionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */
    height: auto;
    border: 1px solid #D9D9D9;
    background-color: transparent;
    color: #ffffff;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 10px 14px;
}

.sharedPaletteActionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */
    height: 33px;
    border: 1px solid #D9D9D9;
    background-color: transparent;
    color: #000000;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 12px;
    padding: 18px 14px;
}

.actionBtn svg:first-child,
.sharedPaletteActionBtn svg:first-child {
    font-size: 14px;
    margin-right: .3rem
}

.actionBtn:hover,
.sharedPaletteActionBtn:hover {
    background-color: #000000;
}

.actionBtn:disabled,
.sharedPaletteActionBtn:disabled {
    background-color: transparent;
    color: #A1A1A1;
    border-color: #A1A1A1
}

.onTopBtn {
    position: relative;
    z-index: 1032;
}

.optionsLabel {
    font-size: 13px;
    font-family: 'Inter Medium';
}

.shareTextInputs::placeholder {
    font-size: 13px;
    color: #d5d2d2;
}


.shareBtnsSharedPalette {
    margin-top: 0;
    display: flex;
    justify-content: flex-start;
}

.shareBtn {
    width: 100%;
    border-radius: 5px;
    background-color: #FF6563;
    border: none;
    font-size: 12px;
    font-family: 'Inter Medium';
    display: flex;
    align-items: center;
    justify-content: center;
}

.shareBtn svg {
    margin-right: .3rem;
}

.optionsSubLabel {
    color: #A1A1A1;
    font-size: 10px;
}

.mediaContainer {
    animation: slideDown 1s ease-out;
    /* Duración de 1s con una salida suave */
}

.mediaCol {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
}

.mediaCol p {
    font-size: 9px;
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 0;
}

.copyLinkBtn {
    background: #c9c9c9;
    color: #000000;
    border: none;
    border-radius: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyLinkBtn:hover {
    background: #c9c9c9;
    color: #000000;
}

.copyLinkBtn:active {
    background: #c9c9c9;
    color: #000000;
}

.fbMessengerIcon {
    color: #6e00fe
}

.downloadTextInputs::placeholder {
    font-size: 13px;
    color: #d5d2d2;
}

.downloadTextInputs {
    animation: slideDown 1s ease-out;
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
        /* Comienza fuera de la vista */
        opacity: 0;
        /* Opción para que aparezca con opacidad */
    }

    100% {
        transform: translateY(0);
        /* Llega a su posición original */
        opacity: 1;
        /* Opción para que esté completamente visible */
    }
}

@media (width < 576px) {
    .shareBtnsSharedPalette {
        justify-content: center;
    }
}